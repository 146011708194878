<template>
    <div class="px-3 py-2">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="700px" no-header id="fix_salary" backdrop style="direction:ltr" right title="اضافة مصروف دوري" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:rtl`">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{$parent.lang.fix_salary}}</span>
                    </div>
                    <div @click="hide" id="fxhideExp" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <v-form ref="apform">
                        <v-row>
                            <v-col cols="12" md="4" sm="12">
                                <label>اجمالي الراتب</label>
                                <b-form-input class="inborder"
                                    label="اجمالي الراتب"
                                    v-model="stotal"
                                    :rules="fieldRules"
                                    type="number"
                                    readonly
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <label>الخصومات</label>
                                <b-form-input class="inborder"
                                    label="الخصومات"
                                    v-model="discounts"
                                    :rules="fieldRules"
                                    type="number"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <label>السلف</label>
                                <b-form-input class="inborder"
                                    label="السلف"
                                    v-model="padvanced"
                                    :rules="fieldRules"
                                    type="number"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <label>المكافآت</label>
                                <b-form-input class="inborder"
                                    label="المكافآت"
                                    v-model="bonuce"
                                    :rules="fieldRules"
                                    type="number"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="4" sm="12" v-if="$store.state.licenseType.cars">
                                <label>نسبة كروت الصيانة</label>
                                <b-form-input class="inborder"
                                    label="نسبة كروت الصيانة"
                                    v-model="ptotal"
                                    :rules="fieldRules"
                                    type="number"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <label>نسبة الفواتير</label>
                                <b-form-input class="inborder"
                                    label="نسبة الفواتير"
                                    v-model="ipercentage"
                                    :rules="fieldRules"
                                    type="number"
                                    ></b-form-input>
                            </v-col>
                            <v-col cols="12" md="6" sm="12">
                                <label>{{$parent.lang.payment_type}}</label>
                                <b-input-group>
                                <b-form-select
                                    class="selborder"
                                    v-model="payment_type"
                                    :options="paytypes"
                                    :label="$parent.lang.payment_type"
                                    ></b-form-select>
                                <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                    </b-input-group-append>
                                </b-input-group>
                            </v-col>
                            <v-col cols="12" md="6" sm="12">
                                <label>ملاحظات على الراتب</label>
                                <b-form-textarea class="inborder"
                                    placeholder="ادخل ملاحظات عن تعديل القيم"
                                    v-model="notes"
                                    style="height:100px;width:100%;"
                                    ></b-form-textarea>
                            </v-col>
                        </v-row>
                        <v-row class="m-2">
                            <v-col cols="4" style="background:darkblue;color:#FFF;text-align:center;">صافي الراتب</v-col>
                            <v-col cols="8" style="background:darkgreen;color:#FFF;text-align:center;">{{paidsalary}}</v-col>
                        </v-row>
                    </v-form>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addit()" class="ma-2" style="width:100px;">{{$parent.lang.save}}</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            empid: 0,
            stotal: 0,
            discounts: 0,
            padvanced: 0,
            bonuce: 0,
            ptotal: 0,
            ipercentage: 0,
            paytypes: [],
            payment_type: 1,
            fieldRules: [
                v => !!v || this.$parent.lang.required_field
            ],
            fixit: false,
            notes: '',
        }
    },
    created(){
        this.getPyamentType();
    },
    computed: {
        paidsalary: function(){
            let t = 0;
            t = parseFloat(this.stotal) + parseFloat(t);
            t = parseFloat(t) - parseFloat(this.discounts);
            t = parseFloat(t) - parseFloat(this.padvanced);
            t = parseFloat(t) + parseFloat(this.bonuce);
            t = parseFloat(t) + parseFloat(this.ptotal);
            t = parseFloat(t) + parseFloat(this.ipercentage);
            return t;
        }
    },
    methods: {
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.paytypes.push({
                        text: this.$parent.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
            })
        },
        changeValue(i){
            if(i == 1){ this.stotal = this.stotal == '' ? 0 : this.stotal;}
            if(i == 2){ this.stotal = this.stotal == '' ? 0 : this.stotal;}
        },
        doitall(){
            this.empid = this.$parent.employees.id ;
            this.stotal = this.$parent.employees.stotal;
            this.discounts = this.$parent.discounts
            this.padvanced = this.$parent.padvanced
            this.bonuce = this.$parent.bonuce
            this.ptotal = this.$parent.ptotal
            this.ipercentage = this.$parent.ipercentage
        },
        addit() {
            
            const post = new FormData();
            post.append("type","fixSalary");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[stotal]",this.stotal);
            post.append("data[discounts]",this.discounts);
            post.append("data[padvanced]",this.padvanced);
            post.append("data[bonuce]",this.bonuce);
            post.append("data[ptotal]",this.ptotal);
            post.append("data[ipercentage]",this.ipercentage);
            post.append("data[month]",this.$parent.month);
            post.append("data[year]",this.$parent.year);
            post.append("data[paidsalary]",this.paidsalary);
            post.append("data[notes]",this.notes);
            post.append("data[empid]",this.empid);
            post.append("data[paytype]",this.payment_type);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // console.log(response.data);
                let message = 'تم اصدار الراتب بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.amount = '',
                this.installment_number = 1;   
                this.$parent.getEmployees();             
                document.getElementById('fxhideExp').click();

            })
        }
    },
}
</script>